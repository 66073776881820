import React, { useState } from "react";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import dateFormat from "dateformat";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, TextField } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { blue, blueGrey, green } from "@material-ui/core/colors";
import { Pagination } from "@material-ui/lab";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4EAB70", //your color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  green: {
    backgroundColor: "#607D8B",
  },
}));

export default function SubjectsList({ course }) {
  const classes = useStyles();

  const subjects = [
    {
      id: 1,
      name: "Estatística Básica",
      acronym: "EB",
    },
    {
      id: 2,
      name: "Fundamentos de Geometria",
      acronym: "FG",
    },
    {
      id: 3,
      name: "Introdução às TIC's",
      acronym: "ITICS",
    },
    {
      id: 4,
      name: "Métodos de Estudo e Habilidades para a Vida",
      acronym: "MEHV",
    },
    {
      id: 5,
      name: "Matemática Básica",
      acronym: "FG",
    },
  ];

  const subjects2 = [
    {
      id: 1,
      name: "Álgebra Linear e Geometria Analítica",
      acronym: "ALGA",
    },
    {
      id: 2,
      name: "Análise Matemática I",
      acronym: "AM-I",
    },
    {
      id: 3,
      name: "Estrutura e Gestão das Organizações ",
      acronym: "EGO",
    },
    {
      id: 4,
      name: "Introdução à Informática",
      acronym: "II",
    },

    {
      id: 5,
      name: "Lógica e Teoria de Conjuntos",
      acronym: "LTC",
    },
  ];

  const [name, setName] = useState("");
  const [semester, setSemester] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [errorNameMessage, setErrorNameMessage] = useState("");

  return (
    <div className="container-fluid">
      <div className="row bg-white">
        <div className="col-12 col-md-6 p-0">
        <div className="semester-title shadow">
          <h6 className="fw-bold">1º Semestre </h6>
        </div>
          <List className={`${classes.root}`}>
            {subjects?.map((subject, index) => (
              <ListItem key={index} className={`py-3`}>
                <ListItemAvatar>
                  <Avatar className={classes.green}>
                    {subject.acronym.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <span className="text-dark">{subject?.acronym} </span>
                  }
                  secondary={subject?.name}
                />
                <IconButton
                  edge="end"
                  aria-label="edit"
                  style={{ color: "#006FB2" }}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </div>
        <div className="col-12 col-md-6 p-0">
        <div className="semester-title shadow">
          <h6 className="fw-bold">2º Semestre </h6>
        </div>
          <List className={`${classes.root}`}>
            {subjects2?.map((subject, index) => (
              <ListItem key={index} className={`py-3`}>
                <ListItemAvatar>
                  <Avatar className={classes.green}>
                    {subject.acronym.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <span className="text-dark">{subject?.acronym} </span>
                  }
                  secondary={subject?.name}
                />
                <IconButton
                  edge="end"
                  aria-label="edit"
                  style={{ color: "#006FB2" }}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
}


{/*       <div className="py-3">
        <h3>
          Lista de Disciplinas do Curso de <br /> {course}
        </h3>
      </div>
      <div className="">
        <form onSubmit={"formSubmit"}>
          <div className="row d-flex align-items-center mb-4 px-4">
            <div className="col-md-4">
              <ThemeProvider theme={theme}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="name"
                  label="Nome:"
                  type={"text"}
                  id="name"
                  value={name}
                  error={errorName}
                  helperText={errorNameMessage}
                  onChange={(e) => setName(e.target.value)}
                />
              </ThemeProvider>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-start">
              <span className="mt-3">Semestre:</span>
              
            <FormControl component="fieldset">
              <RadioGroup row aria-label="gender" className="ml-3 mt-2" name="gender1" value={semester} onChange={(e)=> setSemester(e.target.value)}>
                <FormControlLabel value="1" control={<Radio />} label="1" className="m-0" />
                <FormControlLabel value="2" control={<Radio />} label="2" className="m-0" />
              </RadioGroup>
            </FormControl>
            </div>

            <div className="col-md-4">
              <Button
                className="text-capitalize mr-3"
                style={{ width: 100 }}
                //onClick={() => cleanFields()}
                color="secondary"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="text-capitalize"
                style={{
                  color: "white",
                  width: 100,
                  backgroundColor: "#17A2B8",
                }}
                //onClick={() => requiredFields()}
              >
                Salvar
              </Button>
            </div>
          </div>
        </form>
      </div> */}