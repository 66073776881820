import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo1.png";

export default function Appbar() {
  return (
    <div class="main_menu home_menu">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg navbar-light">
              <NavLink class="navbar-brand" to={"/home"}>
                {" "}
                <img src={logo} alt="logo" style={{width: 200}} />{" "}
              </NavLink>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div
                class="collapse navbar-collapse main-menu-item justify-content-end"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav align-items-center">
                  <NavLink to={"/home"}>
                    <li class="nav-item">
                      <span class="nav-link">Início</span>
                    </li>
                  </NavLink>
                  <NavLink to={"/help"}>
                    <li class="nav-item">
                      <span class="nav-link">Ajuda</span>
                    </li>
                  </NavLink>
                  <li class="nav-item">
                    {" "}
                    <NavLink to={"/regist/student"} className="btn_1">
                      Me Registar{" "}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
