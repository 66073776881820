import "./App.css";
import "./assets/css/style.css";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
import Routing from "./routes/routes";
import "bootstrap/dist/js/bootstrap.min.js";
import $ from "jquery";

function App() {
  // menu fixed js code
  $(window).scroll(function () {
    var window_top = $(window).scrollTop() + 1;
    if (window_top > 50) {
      $(".main_menu").addClass("menu_fixed animated fadeInDown");
    } else {
      $(".main_menu").removeClass("menu_fixed animated fadeInDown");
    }
  });

  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
