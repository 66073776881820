import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import SubjectsManagement from "../../../components/dashboard/subjectsManagement/gestaoSubjects";

const SubjectsPage = () => {
  return (
    <div>
      <AppbarDrawer>
        <SubjectsManagement />
      </AppbarDrawer>
    </div>
  );
};
export default SubjectsPage;
