import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useParams, useSearchParams } from "react-router-dom";
import { getSubjects } from "../../utils/subjects";
import MaterialList from "../tabContent.js/materialList";
import { Button, ButtonGroup, Chip, Collapse, createTheme, FormControl, FormControlLabel, FormHelperText, IconButton, Radio, RadioGroup, TextField, ThemeProvider } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import FloatButton from "../floatButton";
import { Add, Edit } from "@material-ui/icons";
import { AdicionarMaterial } from "../addMaterial";
import courses from "../../utils/courses.json";
import SubjectsList from "./subjectsList";
import { Spinner } from "react-bootstrap";
import SnackBar from "../snackbar";

const formTheme = createTheme({
  palette: {
    primary: {
      main: "#4EAB70", //your color
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "orange",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "green",
      borderTop: "none",
    },
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },

  buttons: {
    "&.MuiButton-outlined": {
      borderColor: "rgba(24, 119, 212, 0.1)",
      textTransform: "capitalize",
      paddingLeft: 27,
      paddingRight: 27,
    },
  },
}));

export default function SubjectsManagement() {
  const { course } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const closeAddMaterial = () => {
    setShowAddMaterial(false);
  };


  /* ********FORM VAriables******* */
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [acronym, setAcronym] = useState("");
  const [semester, setSemester] = useState("");

  const [errorName, setErrorName] = useState(false);
  const [errorSemester, setErrorSemester] = useState(false);
  const [errorAcronym, setErrorAcronym] = useState(false);

  const [open, setOpen] = useState();
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");

  const closeSnackbar = () => {
    setOpen(false);
  };


  const requiredFields = () => {
    if (name === "" || name === undefined) {
      setErrorName(true);
    } else {
      setErrorName(false);
    }

    if (acronym === "" || acronym === undefined) {
      setErrorAcronym(true);
    } else {
      setErrorAcronym(false);
    }

    if (semester === "" || semester === undefined) {
      setErrorSemester(true);
    } else {
      setErrorSemester(false);
    }
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorName === false && errorAcronym === false && errorSemester === false) {
      salvar()
    } else {
      console.log("NÃO");
    }
  };

  const dados = {
    name: name,
    sigla: acronym,
    course: courses[value].name,
    semester: 0,
  };
  
  const salvar = () => {
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        name: dados.name,
        level: dados.level,
        sigla: dados.sigla,
        semester: dados.semester,
      }),
    };
    fetch(
      "https://tivanegamestudio.co.mz/api_maza.php/api/subjects/save",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setOpen(true);
          setColor("success")
          setMessage("Disciplina registada com sucesso!")
          setLoader(false);
          cleanFields();
        } else{
          setColor("error")
          setMessage("Ocorreu um erro ao registar disciplina!")
        }
      }).catch((err)=>{
        setColor("error")
        setMessage("Ocorreu um erro ao registar disciplina!")
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };


  const [openForm, setOpenForm] = useState(false);

  const handleOpenForm = () =>{
    setOpenForm(!openForm);
  }

  const cleanFields = () => {
    setName("");
    setAcronym("");
    setSemester("");
    setErrorName(false)
    setErrorAcronym(false)
    setErrorSemester(false)
  };

  return (
    <div className="">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          {courses?.map((course, index) => (
            <Tab key={index} label={course.name} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>

      <div className="bg-white shadow-sm">
        <div className="d-flex justify-content-center row">
          <h4 className="col-11 my-4">
            Lista de Disciplinas do Curso de <br /> {courses[value].name}
          </h4>
          <div className="col-1 d-flex justify-content-end align-items-center pr-4">
            <IconButton hidden={openForm} style={{backgroundColor: "#4EAB70", color: "#fff"}} onClick={handleOpenForm} ><Add/></IconButton>
          </div>
        </div>

        <Collapse in={openForm}>
          <div className="">
            <form onSubmit={formSubmit}>
              <div className="row mb-4 px-4">
                <div className="col-md-5 mt-2">
                    <div className="w-100 d-flex justify-content-start">Nome:</div>
                    <TextField
                      variant="standard"
                      fullWidth
                      name="name"
                      type={"text"}
                      placeholder="Nome da disciplina"
                      id="name"
                      className="shadow-sm rounded border-bottom"
                      value={name}
                      error={errorName}
                      onChange={(e) => setName(e.target.value)}
                      InputProps={{disableUnderline: true}}
                      inputProps={{style:{border: "none", paddingLeft: 10}}}
                    />
                  {errorName ? <FormHelperText className="text-danger">O Nome da disciplina é obrigatório!</FormHelperText> : <FormHelperText>(Curso de {courses[value].name})</FormHelperText>}
                </div>
                <div className="col-md-2 mt-2">
                    <div className="w-100 d-flex justify-content-start">Sigla:</div>
                    <TextField
                      variant="standard"
                      fullWidth
                      name="acronym"
                      type={"text"}
                      placeholder="ex: ALGA"
                      id="acronym"
                      className="shadow-sm rounded border-bottom"
                      value={acronym.toUpperCase()}
                      error={errorAcronym}
                      onChange={(e) => setAcronym(e.target.value)}
                      InputProps={{disableUnderline: true}}
                      inputProps={{style:{border: "none", paddingLeft: 10}}}
                    />
                  {errorAcronym && <FormHelperText className="text-danger">A Sigla é obrigatória!</FormHelperText>}
                </div>
                <div className="col-md-3 mt-2">
                  <small className="" style={{fontSize: 14}}>Semestre:</small>
                  
                <FormControl component="fieldset" error={errorSemester}>
                  <RadioGroup row aria-label="gender" className="mt-2" name="gender1" value={semester} onChange={(e)=> setSemester(e.target.value)}>
                    <FormControlLabel value="1" control={<Radio style={{color: "#4EAB70"}}/>} label="1º" className="m-0" />
                    <FormControlLabel value="2" control={<Radio style={{color: "#4EAB70"}} />} label="2º" className="m-0 ml-3" />
                  </RadioGroup>
                  {errorSemester && <FormHelperText>Seleccione o semestre!</FormHelperText>}
                </FormControl>
                </div>

                <div className="col-md-2 d-flex">
                  <Button
                    className="text-capitalize mr-3 my-auto"
                    style={{ width: 100 }}
                    onClick={()=>{handleOpenForm(); cleanFields()}}
                    color="secondary"
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="text-capitalize px-4 my-auto"
                    style={{
                      color: "white",
                      width: 100,
                      backgroundColor: "#4EAB70",
                    }}
                    onClick={() => requiredFields()}
                    disabled={loader}
                  >
                    {loader ? (
                      <Spinner
                        animation="border"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : (
                      "Registar"
                    )}
                    
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Collapse>
        
      </div>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <SubjectsList course={courses[value].name} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <SubjectsList course={courses[value].name} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <SubjectsList course={courses[value].name} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <SubjectsList course={courses[value].name} />
        </TabPanel>
      </SwipeableViews>

      <AdicionarMaterial
        show={showAddMaterial}
        closeModal={closeAddMaterial}
        value={value}
      />

      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={message}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />

      
    </div>
  );
}
