import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import Material from "../../../components/dashboard/gestaoMaterial";

const MaterialPage = () => {
  return (
    <div>
      <AppbarDrawer>
        <Material />
      </AppbarDrawer>
    </div>
  );
};
export default MaterialPage;
