import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  TextareaAutosize,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import CloseIcon from "@material-ui/icons/Close";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Done, HourglassEmpty, PhotoCamera } from "@material-ui/icons";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import gmail from "../../../assets/img/gmail.png";
import phone from "../../../assets/img/phone.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4EAB70", //your color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const SeeStudent = ({ show, closeModal, student }) => {
  const classes = useStyles();

  const { course, semester } = useParams();
  const [loader, setLoader] = useState(false);

  return (
    <>
      <Dialog
        open={show}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title" className="fw-bold text-center">
          Detalhes do estudante
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="">
              <div className="row p-3 g-4 d-flex justify-content-center align-items-center border-bottom rounded">
                <div className="col-2">
                  <a
                    href={
                      student?.image_link === "Sem imagem"
                        ? null
                        : student?.image_link
                    }
                    target="_blank"
                  >
                    <Avatar
                      src={
                        student?.image_link === "Sem imagem"
                          ? null
                          : student?.image_link
                      }
                      alt={student?.name}
                      title={student?.name}
                      className="avatar-detalhes border"
                      style={{
                        width: 70,
                        height: 70,
                      }}
                    />
                  </a>
                </div>
                <div className="col-7 px-4">
                  <span
                    className="pt-1 fw-bold text-mains text-dark"
                    style={{ fontSize: 17 }}
                  >
                    {student?.name}
                  </span>{" "}
                  <br />
                  <small> Estudante</small>
                </div>
                <div className="col-3 d-flex justify-content-end">
                  <IconButton color="primary" className="fw-bold">
                    <a href={`tel:+${student?.phone}`}>
                      <img src={phone} style={{ width: 27 }} />
                    </a>
                  </IconButton>
                  <IconButton color="primary" className="fw-bold">
                    <a href={`mailto:+${student?.email}`}>
                      <img src={gmail} style={{ width: 21 }} />
                    </a>
                  </IconButton>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <small>Curso:</small> <br />
                  <p className="text-mains text-dark fw-bold">
                    {student?.course || "---"}
                  </p>
                </div>

                <div className="col-md-6">
                  <small>Regime:</small> <br />
                  <p className="text-mains text-dark fw-bold">
                    {student?.regime || "---"}
                  </p>
                </div>

                <div className="col-md-6">
                  <small>Contacto:</small> <br />
                  <p className="text-mains text-dark fw-bold">
                    {student?.phone || "---"}
                  </p>
                </div>
                <div className="col-md-6">
                  <small>Email:</small> <br />
                  <p className="text-mains text-dark fw-bold">
                    {student?.email || "---"}
                  </p>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
