import React from "react";
import Help from "../../components/help";

const HelpPage = () => {
  return (
    <div>
      <Help />
    </div>
  );
};
export default HelpPage;
