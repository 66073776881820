import React from "react";
import Home from "../../components/home";



const HomePage = () => {
  return (
    <div >
      <Home />
    </div>
  );
};
export default HomePage;

