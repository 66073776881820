import React from "react";
import Appbar from "./appbar";
import Footer from "./footer";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo1.png";
import { useState } from "react";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";
import SnackBar from "./snackbar";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { storage } from "../firebase";
import { Icon } from "@iconify/react";
import { Camera, CameraAlt, Edit, Home, Photo, Schedule, School, Search } from "@material-ui/icons";
import FloatButton from "./floatButton";

const theme = createTheme({
  palette: {
    primary: {
      main: "#55C7A9", //your color
    },
  },
});
const Registar = () => {
  const [nome, setNome] = useState();
  const [curso, setCurso] = useState("");
  const [regime, setRegime] = useState("");
  const [senha, setSenha] = useState();
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [contacto, setContacto] = useState();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [link, setLink] = useState();
  let navigate = useNavigate();

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorCurso, setErrorCurso] = useState(false);
  const [errorCursoMessage, setErrorCursoMessage] = useState("");

  const [errorRegime, setErrorRegime] = useState(false);
  const [errorRegimeMessage, setErrorRegimeMessage] = useState("");

  const [errorUsername, setErrorUsername] = useState(false);
  const [errorUsernameMessage, setErrorUsernameMessage] = useState("");

  const [errorSenha, setErrorSenha] = useState(false);
  const [errorSenhaMessage, setErrorSenhaMessage] = useState(
    "*Pelo menos 6 digitos"
  );

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorContacto, setErrorContacto] = useState(false);
  const [errorContactoMessage, setErrorContactoMessage] = useState("");

  const dados = {
    nome: nome,
    curso: curso,
    regime: regime,
    senha: senha,
    email: email,
    contacto: contacto,
    username: username,
    tipo: "Normal",
    estado: "Activo",
  };

  const cleanFields = () => {
    setNome("");
    setCurso("");
    setRegime("");
    setSenha("");
    setEmail("");
    setUsername("");
    setContacto("");
    setLink("");
    setImage1("Nenhum ficheiro seleccionado.");
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorCurso === false &&
      errorRegime === false &&
      errorContacto === false &&
      errorEmail === false
    ) {
      handleUpload();
    } else {
      console.log("N");
    }
  };

  const requiredFields = () => {
    const re = /^[0-9\b]+$/;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (curso === "" || curso === undefined) {
      setErrorCurso(true);
      setErrorCursoMessage("Por favor, preencha este campo!");
    } else {
      setErrorCurso(false);
      setErrorCursoMessage(true);
    }
    if (regime === "" || regime === undefined) {
      setErrorRegime(true);
      setErrorRegimeMessage("Por favor, preencha este campo!");
    } else {
      setErrorRegime(false);
      setErrorRegimeMessage(true);
    }
    if (username === "" || username === undefined) {
      setErrorUsername(true);
      setErrorUsernameMessage("Por favor, preencha este campo!");
    } else {
      setErrorUsername(false);
      setErrorUsernameMessage(true);
    }
    if (senha === "" || senha === undefined || senha?.length < 6) {
      setErrorSenha(true);
      setErrorSenhaMessage("Senha inválida, pelo menos 6 dígitos!");
    } else {
      setErrorSenha(false);
      setErrorSenhaMessage(true);
    }
    if (
      contacto === "" ||
      contacto?.length < 9 ||
      contacto === undefined ||
      !contacto?.match(re)
    ) {
      setErrorContacto(true);
      setErrorContactoMessage(
        "Número de telefone inválido, campo obrigatório!"
      );
    } else {
      setErrorContacto(false);
      setErrorContactoMessage(true);
    }
    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }
  };

  const salvar = (link) => {
    //console.log(dados);
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        name: dados.nome,
        course: dados.curso,
        regime: dados.regime,
        phone: dados.contacto,
        email: dados.email,
        image_link: link,
      }),
    };
    fetch(
      "https://tivanegamestudio.co.mz/api_maza.php/api/estudantes/save",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.success) {
          cleanFields();
          setShow(true);
          setMessage("Os dados foram registados com sucesso!");
          setColor("success");
        } else {
          setShow(true);
          setMessage("Ocorreu um erro ao registar, por favor tente novamente!");
          setColor("error");
        }
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [message, setMessage] = useState(false);
  const [color, setColor] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [image1, setImage1] = useState("Nenhum ficheiro seleccionado.");

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImage1(e.target.files[0].name);
    }
  };

  const handleUpload = () => {
    setLoader(true);
    if (image) {
      console.log("Com imagem");
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setLink(url);
              setImage1(image.name);
              salvar(url);
              console.log(url);
            });
        }
      );
    } else {
      console.log("Sem imagem");
      salvar("Sem imagem");
    }
  };

  return (
    <div>
      <div className="container-fluid py-5 hero-header-login">
        <div className="container text-center my-5 pt-1 pb-4">
          <Paper
            className="container paper  justify-content-center pe-5 "
            elevation={5}
            style={{ paddingLeft: 11 }}
          >
            <div className="row g-4">
              <div
                className="col-md-6 wow fadeIn d-flex justify-content-center align-items-center"
                data-wow-delay="0.1s"
                //style={{ height: '1rem' }}
                style={{ backgroundColor: "#FFF7F5" }}
              >
                <div className=" py-2">
                  <small className=" ff-secondary text-center fw-normal ">
                    Bem vindo ao
                  </small>{" "}
                  <br />
                  <img
                    src={logo}
                    alt="Holding hands"
                    style={{ width: "70%" }}
                  />{" "}
                  <br />
                  <small className="section-title ff-secondary text-center fw-normal ">
                    Hoje Caloiro, amanhã Maza!
                  </small>
                </div>
              </div>
              <div className="col-md-6 pb-5 ps-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                  <h3 className="my-4">Registe-se</h3>
                </div>
                <div className="wow fadeInUp mt-4" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-3">
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            size="small"
                            fullWidth
                            name="nome"
                            label="Nome Completo"
                            type={"text"}
                            id="nome"
                            value={nome}
                            defaultValue={nome}
                            error={errorNome}
                            helperText={errorNomeMessage}
                            onChange={(e) => setNome(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            select
                            variant="standard"
                            margin="normal"
                            size="small"
                            fullWidth
                            className="text-start"
                            name="curso"
                            label="Curso:"
                            type={"text"}
                            id="curso"
                            value={curso}
                            defaultValue={curso}
                            error={errorCurso}
                            helperText={errorCursoMessage}
                            onChange={(e) => setCurso(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <School style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                            /* InputProps={{
                            disableUnderline: true,
                            style: {
                              paddingLeft: 10,
                              color: curso === " " && "rgba(0,0,0,0.5)",
                            },
                          }}
                          inputProps={{ style: { border: "none" } }}
                          disabled={loader} */
                          >
                            <MenuItem
                              value={" "}
                              disabled
                              className="text-secondary"
                            >
                              Seleccione o curso
                            </MenuItem>
                            <MenuItem value={"Informática"}>
                              Informática
                            </MenuItem>
                            <MenuItem value={"Matemática"}>Matemática</MenuItem>
                            <MenuItem value={"Estatística"}>
                              Estatística
                            </MenuItem>
                            <MenuItem
                              value={"Ciências de Informação Geográfica"}
                            >
                              Ciências de Informação Geográfica
                            </MenuItem>
                          </TextField>
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            select
                            variant="standard"
                            margin="normal"
                            size="small"
                            fullWidth
                            className="text-start"
                            name="regime"
                            label="Regime:"
                            type={"text"}
                            id="regime"
                            value={regime}
                            defaultValue={regime}
                            error={errorRegime}
                            helperText={errorRegimeMessage}
                            onChange={(e) => setRegime(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Schedule style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                            /* InputProps={{
                            disableUnderline: true,
                            style: {
                              paddingLeft: 10,
                              color: curso === " " && "rgba(0,0,0,0.5)",
                            },
                          }}
                          inputProps={{ style: { border: "none" } }}
                          disabled={loader} */
                          >
                            <MenuItem
                              value={" "}
                              disabled
                              className="text-secondary"
                            >
                              Seleccione o regime
                            </MenuItem>
                            <MenuItem value={"Laboral"}>Laboral</MenuItem>
                            <MenuItem value={"Pós Laboral"}>
                              Pós Laboral
                            </MenuItem>
                          </TextField>
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            size="small"
                            fullWidth
                            name="email"
                            label="Email"
                            type={"text"}
                            id="email"
                            value={email}
                            defaultValue={email}
                            error={errorEmail}
                            helperText={errorEmailMessage}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="standard"
                            margin="normal"
                            size="small"
                            fullWidth
                            name="contacto"
                            label="Contacto"
                            type={"text"}
                            id="contacto"
                            value={contacto}
                            defaultValue={contacto}
                            error={errorContacto}
                            helperText={errorContactoMessage}
                            onChange={(e) => setContacto(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CallIcon style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-md-12 mt-2 mt-md-4 mb-3">
                        <div className="d-flex align-items-center">
                          {" "}
                          <input
                            className="upload-photo"
                            id="contained-button-file"
                            multiple
                            onChange={handleChangeImage}
                            type="file"
                            disabled={loader}
                          />
                          <label htmlFor="contained-button-file" style={{cursor: "pointer"}}>
                            <Badge
                              className="ml-2 mr-4"
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                image1 === "Nenhum ficheiro seleccionado." ? (
                                  <CameraAlt fontSize="small" />
                                ) : (
                                  <Edit fontSize="small" />
                                )
                              }
                            >
                              <Avatar alt="Travis Howard" />
                            </Badge>
                          </label>
                          <div className="">
                            <small
                              id="file-chosen"
                              className={`text-secondary`}
                            >
                              {image1}
                            </small>
                          </div>
                        </div>
                      </div>
                      {/*  <div className="col-12 text-end">
                        <NavLink
                          to={"/login"}
                          style={{ textDecoration: "underline", color: "grey" }}
                          className="link-login"
                        >
                          <small for="senha">Já tenho uma conta! Entrar</small>
                        </NavLink>
                      </div> */}

                      <div className="col-12">
                        <Button
                          variant="contained"
                          type="submit"
                          className=" w-100 text-light fw-bold text-capitalize"
                          //startIcon={<SaveIcon />}
                          onClick={() => requiredFields()}
                          disabled={loader}
                          style={{ backgroundColor: "#00AB7F" }}
                        >
                          {loader ? "A enviar dados" : "Registar"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Paper>

          <div
            className="container paper1 border shadow"
            style={{ marginTop: 35 }}
          >
            <div className="row g-4" style={{ marginTop: -80 }}>
              <div
                className="col-md-6 wow fadeIn bg-light p-3 border rounded"
                data-wow-delay="0.1s"
              >
                <div
                  className="shadow py-2 rounded"
                  style={{ backgroundColor: "#FFF7F5" }}
                >
                  <small className=" ff-secondary text-center fw-normal ">
                    Bem vindo ao
                  </small>{" "}
                  <br />
                  <img
                    src={logo}
                    alt="Holding hands"
                    style={{ width: "70%" }}
                  />{" "}
                  <br />
                  <small className="section-title ff-secondary text-center fw-normal ">
                    Hoje Caloiro, amanhã Maza!
                  </small>
                </div>

                <h5 className="mt-2">Registe-se</h5>
              </div>
              <div className="col-md-6 bg-light mt-0 pb-4">
                <div className="wow fadeInUp" data-wow-delay="0.2s">
                  <form onSubmit={formSubmit}>
                    <div className="row g-3">
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            name="nome"
                            label="Nome Completo"
                            type={"text"}
                            id="nome"
                            value={nome}
                            defaultValue={nome}
                            error={errorNome}
                            helperText={errorNomeMessage}
                            onChange={(e) => setNome(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            select
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            className="text-start"
                            name="curso"
                            label="Curso:"
                            type={"text"}
                            id="curso"
                            value={curso}
                            defaultValue={curso}
                            error={errorCurso}
                            helperText={errorCursoMessage}
                            onChange={(e) => setCurso(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <School style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                            /* InputProps={{
                            disableUnderline: true,
                            style: {
                              paddingLeft: 10,
                              color: curso === " " && "rgba(0,0,0,0.5)",
                            },
                          }}
                          inputProps={{ style: { border: "none" } }}
                          disabled={loader} */
                          >
                            <MenuItem
                              value={" "}
                              disabled
                              className="text-secondary"
                            >
                              Seleccione o curso
                            </MenuItem>
                            <MenuItem value={"Informática"}>
                              Informática
                            </MenuItem>
                            <MenuItem value={"Matemática"}>Matemática</MenuItem>
                            <MenuItem value={"Estatística"}>
                              Estatística
                            </MenuItem>
                            <MenuItem
                              value={"Ciências de Informação Geográfica"}
                            >
                              Ciências de Informação Geográfica
                            </MenuItem>
                          </TextField>
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            select
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            className="text-start"
                            name="regime"
                            label="Regime:"
                            type={"text"}
                            id="regime"
                            value={regime}
                            defaultValue={regime}
                            error={errorRegime}
                            helperText={errorRegimeMessage}
                            onChange={(e) => setRegime(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Schedule style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                            /* InputProps={{
                            disableUnderline: true,
                            style: {
                              paddingLeft: 10,
                              color: curso === " " && "rgba(0,0,0,0.5)",
                            },
                          }}
                          inputProps={{ style: { border: "none" } }}
                          disabled={loader} */
                          >
                            <MenuItem
                              value={" "}
                              disabled
                              className="text-secondary"
                            >
                              Seleccione o regime
                            </MenuItem>
                            <MenuItem value={"Laboral"}>Laboral</MenuItem>
                            <MenuItem value={"Pós Laboral"}>
                              Pós Laboral
                            </MenuItem>
                          </TextField>
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            name="email"
                            label="Email"
                            type={"text"}
                            id="email"
                            value={email}
                            defaultValue={email}
                            error={errorEmail}
                            helperText={errorEmailMessage}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="col-12">
                        <ThemeProvider theme={theme}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            name="contacto"
                            label="Contacto"
                            type={"text"}
                            id="contacto"
                            value={contacto}
                            defaultValue={contacto}
                            error={errorContacto}
                            helperText={errorContactoMessage}
                            onChange={(e) => setContacto(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CallIcon style={{ color: "#55C7A9" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ThemeProvider>
                      </div>

                      <div className="col-md-12 mt-2 mt-md-4 mb-3">
                        <div className="d-block d-md-flex justify-content-md-between align-items-center">
                          {" "}
                          <input
                            className="upload-photo"
                            id="contained-button-file"
                            multiple
                            onChange={handleChangeImage}
                            type="file"
                            disabled={loader}
                          />
                          <label htmlFor="contained-button-file">
                            {/* <Button
                              style={{
                                textTransform: "capitalize",
                                color: "#17A2B8",
                                backgroundColor: "#F5F5F5",
                              }}
                              className="text-center shadow-sm px-3 py-2"
                              component="span"
                              startIcon={
                                <Icon
                                  icon="material-symbols:upload-rounded"
                                  width="24"
                                  height="24"
                                  inline={true}
                                />
                              }
                              disabled={loader}
                            >
                              {" "}
                              {image1 === "Nenhum ficheiro seleccionado."
                                ? "Carregar "
                                : "Alterar "}
                              {"Ficheiro"}
                            </Button> */}

                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                image1 === "Nenhum ficheiro seleccionado." ? (
                                  <CameraAlt fontSize="small" />
                                ) : (
                                  <Edit fontSize="small" />
                                )
                              }
                            >
                              <Avatar alt="Travis Howard" />
                            </Badge>
                          </label>
                          <div className="">
                            <small
                              id="file-chosen"
                              className={`text-secondary`}
                            >
                              {image1}
                            </small>
                          </div>
                        </div>
                      </div>
                      {/*  <div className="col-12 text-end">
                        <NavLink
                          to={"/login"}
                          style={{ textDecoration: "underline", color: "grey" }}
                          className="link-login"
                        >
                          <small for="senha">Já tenho uma conta! Entrar</small>
                        </NavLink>
                      </div> */}

                      <div className="col-12">
                        <Button
                          variant="contained"
                          type="submit"
                          className=" w-100 text-light fw-bold text-capitalize"
                          //startIcon={<SaveIcon />}
                          onClick={() => requiredFields()}
                          disabled={loader}
                          style={{ backgroundColor: "#00AB7F" }}
                        >
                          {loader ? "A enviar dados" : "Registar"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <FloatButton
      size={"small"}
        title="Home"
        color="#00AB7F"
        backgroundColor="#fff"
        icon={<Home />}
        action={() => navigate("/")}
      />

      <SnackBar
        open={show}
        handleClose={handleClose}
        message={message}
        key={"bottom" + "center"}
        autoHideDuration={4000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
};

export default Registar;
