import React, { useState } from "react";
import Appbar from "./appbar";
import Footer from "./footer";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { TextField } from "@material-ui/core";
import emailjs from "@emailjs/browser";
import SnackBar from "./snackbar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#55C7A9", //your color
    },
  },
});

export default function Help() {
  const [nome, setNome] = useState();
  const [curso, setCurso] = useState("");
  const [assunto, setAssunto] = useState("");
  const [email, setEmail] = useState();
  const [mensagem, setMensagem] = useState();
  const [contacto, setContacto] = useState();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [link, setLink] = useState();
  let navigate = useNavigate();

  const [errorNome, setErrorNome] = useState(false);
  const [errorNomeMessage, setErrorNomeMessage] = useState("");

  const [errorCurso, setErrorCurso] = useState(false);
  const [errorCursoMessage, setErrorCursoMessage] = useState("");

  const [errorAssunto, setErrorAssunto] = useState(false);
  const [errorAssuntoMessage, setErrorAssuntoMessage] = useState("");

  const [errorMensagem, setErrorMensagem] = useState(false);
  const [errorMensagemMessage, setErrorMensagemMessage] = useState("");

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");

  const [errorContacto, setErrorContacto] = useState(false);
  const [errorContactoMessage, setErrorContactoMessage] = useState("");

  const cleanFields = () => {
    setNome("");
    setCurso("");
    setAssunto("");
    setEmail("");
    setMensagem("");
    setContacto("");
    setLink("");
  };

  const formSubmit = (event) => {
    event.preventDefault();

    if (
      errorNome === false &&
      errorCurso === false &&
      errorAssunto === false &&
      errorMensagem === false &&
      errorContacto === false &&
      errorEmail === false
    ) {
      sendmail();
      console.log("Sim, salvo sim");
    } else {
      console.log("N");
    }
  };

  const requiredFields = () => {
    const re = /^[0-9\b]+$/;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (nome === "" || nome === undefined) {
      setErrorNome(true);
      setErrorNomeMessage("Por favor, preencha este campo!");
    } else {
      setErrorNome(false);
      setErrorNomeMessage(true);
    }
    if (curso === "" || curso === undefined) {
      setErrorCurso(true);
      setErrorCursoMessage("Por favor, preencha este campo!");
    } else {
      setErrorCurso(false);
      setErrorCursoMessage(true);
    }
    if (assunto === "" || assunto === undefined) {
      setErrorAssunto(true);
      setErrorAssuntoMessage("Por favor, preencha este campo!");
    } else {
      setErrorAssunto(false);
      setErrorAssuntoMessage(true);
    }
    if (mensagem === "" || mensagem === undefined) {
      setErrorMensagem(true);
      setErrorMensagemMessage("Por favor, preencha este campo!");
    } else {
      setErrorMensagem(false);
      setErrorMensagemMessage(true);
    }
    if (
      contacto === "" ||
      contacto?.length < 9 ||
      contacto === undefined ||
      !contacto?.match(re)
    ) {
      setErrorContacto(true);
      setErrorContactoMessage(
        "Número de telefone inválido, campo obrigatório!"
      );
    } else {
      setErrorContacto(false);
      setErrorContactoMessage(true);
    }
    if (email === "" || email === undefined || !email?.match(mailformat)) {
      setErrorEmail(true);
      setErrorEmailMessage("Endereço de email inválido, campo obrigatório!");
    } else {
      setErrorEmail(false);
      setErrorEmailMessage(true);
    }
  };

  const [message, setMessage] = useState(false);
  const [color, setColor] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const [sending, setSending] = useState(false);

  (function () {
    emailjs.init("uijFDqMZ7GA2YjGGS");
  })();

  function sendmail() {
    setSending(true);
    var contactParams = {
      nome: nome,
      curso: curso,
      contacto: contacto,
      email: email,
      assunto: assunto,
      message: mensagem,
    };

    emailjs.send("service_6gx4sn8", "template_6fmib5k", contactParams).then(
      (result) => {
        cleanFields(false);
        setShow(true);
        setMessage("A sua mesnagem foi enviada!");
        setColor("success");
    setSending(false);
},
      (error) => {
        setShow(true);
        setMessage("Erro ao enviar mensagem, por favor tente novamente!");
        setColor("error");
        console.log(error.text);
    setSending(false);
}
    );
  }

  return (
    <div className="">
      <Appbar />

      <section class="breadcrumb breadcrumb_bg">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb_iner text-center">
                <div class="breadcrumb_iner_item">
                  <h2 className="text-dark">De que você precisa?</h2>
                  <p className="text-dark">Contacte-nos para que possamos lhe ajudar.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="contact-section py-5">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 class="contact-title">Escreva-nos</h2>
            </div>
            <div class="col-lg-8">
              <form onSubmit={formSubmit}>
                <div className="row g-3">
                  <div className="col-12">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="standard"
                        margin="dense"
                        size="small"
                        fullWidth
                        className="text-start"
                        name="assunto"
                        placeholder="Assunto"
                        type={"text"}
                        id="assunto"
                        value={assunto}
                        defaultValue={assunto}
                        error={errorAssunto}
                        helperText={errorAssuntoMessage}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                          style: {
                            border: "1px solid #F0E9FF",
                            borderRadius: "3px",
                            padding: "10px",
                          },
                        }}
                        onChange={(e) => setAssunto(e.target.value)}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="col-12">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        fullWidth
                        name="mensagem"
                        placeholder="Escreva aqui a sua mensagem..."
                        multiline
                        rows={3}
                        type={"text"}
                        id="mensagem"
                        value={mensagem}
                        defaultValue={mensagem}
                        error={errorMensagem}
                        helperText={errorMensagemMessage}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                          style: {
                            border: "1px solid #F0E9FF",
                            borderRadius: "3px",
                            padding: "10px",
                          },
                        }}
                        onChange={(e) => setMensagem(e.target.value)}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="col-12 col-md-6">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        size="small"
                        fullWidth
                        name="nome"
                        placeholder="Nome Completo"
                        type={"text"}
                        id="nome"
                        value={nome}
                        defaultValue={nome}
                        error={errorNome}
                        helperText={errorNomeMessage}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                          style: {
                            border: "1px solid #F0E9FF",
                            borderRadius: "3px",
                            padding: "10px",
                          },
                        }}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="col-12 col-md-6">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        size="small"
                        fullWidth
                        className="text-start"
                        name="curso"
                        placeholder="Curso"
                        type={"text"}
                        id="curso"
                        value={curso}
                        defaultValue={curso}
                        error={errorCurso}
                        helperText={errorCursoMessage}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                          style: {
                            border: "1px solid #F0E9FF",
                            borderRadius: "3px",
                            padding: "10px",
                          },
                        }}
                        onChange={(e) => setCurso(e.target.value)}
                      />
                    </ThemeProvider>
                  </div>

                  <div className="col-12 col-md-6">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        size="small"
                        fullWidth
                        name="email"
                        placeholder="Email"
                        type={"text"}
                        id="email"
                        value={email}
                        defaultValue={email}
                        error={errorEmail}
                        helperText={errorEmailMessage}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                          style: {
                            border: "1px solid #F0E9FF",
                            borderRadius: "3px",
                            padding: "10px",
                          },
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="col-12 col-md-6">
                    <ThemeProvider theme={theme}>
                      <TextField
                        variant="standard"
                        margin="normal"
                        size="small"
                        fullWidth
                        name="contacto"
                        placeholder="Contacto"
                        type={"text"}
                        id="contacto"
                        value={contacto}
                        defaultValue={contacto}
                        error={errorContacto}
                        helperText={errorContactoMessage}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{
                          style: {
                            border: "1px solid #F0E9FF",
                            borderRadius: "3px",
                            padding: "10px",
                          },
                        }}
                        onChange={(e) => setContacto(e.target.value)}
                      />
                    </ThemeProvider>
                  </div>
                  <div className="col-12 d-flex justify-content-center mt-3 mb-5">
                    <button
                      type="submit"
                      class="button button-contactForm btn_1 my-3 text-capitalize"
                      onClick={() => requiredFields()}
                      disabled={loader}
                    >
                        {sending ? "A enviar mensagem..." : "Enviar mensagem"}
                      
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-4">
              <div class="media contact-info">
                <span class="contact-info__icon">
                  <i class="ti-home"></i>
                </span>
                <div class="media-body">
                  <h3>Maputo, Moçambique.</h3>
                  <p> Avenida Julius Nyerere, Nº 3453</p>
                </div>
              </div>
              <div class="media contact-info">
                <span class="contact-info__icon">
                  <i class="ti-tablet"></i>
                </span>
                <div class="media-body">
                  <h3>(+258) 875 085 818</h3>
                  <p>Segunda à Sexta, 08:00h - 15:30h</p>
                </div>
              </div>
              <div class="media contact-info">
                <span class="contact-info__icon">
                  <i class="ti-email"></i>
                </span>
                <div class="media-body">
                  <h3>soumaza.nedmi@gmail.com</h3>
                  <p>Núcleo de Estudantes - DMI</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <SnackBar
        open={show}
        handleClose={handleClose}
        message={message}
        key={"bottom" + "center"}
        autoHideDuration={4000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor={color}
      />
    </div>
  );
}
