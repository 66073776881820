import React, { useState, useEffect } from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {
  CircularProgress,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import { Call, InfoOutlined } from "@material-ui/icons";
import { SeeStudent } from "./studentDetails";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4EAB70", //your color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  green: {
    backgroundColor: "#607D8B",
  },
}));

export default function StudentsList({ course }) {
  const classes = useStyles();

  const laborall = [
    {
      id: 1,
      name: "Alzira Francisco Xerinda",
      phone: "845656784",
    },
    {
      id: 2,
      name: "Edmilson Kayce Novele",
      phone: "845656784",
    },
    {
      id: 3,
      name: "Carlos Macaneta",
      phone: "845656784",
    },
    {
      id: 4,
      name: "Neide Loumina Reginaldo Souto",
      phone: "845656784",
    },
    {
      id: 5,
      name: "Zefanias Rodrigues Tembe",
      phone: "845656784",
    },
  ];

  const posLaborall = [
    {
      id: 1,
      name: "Alzira Francisco Xerinda",
      phone: "845656784",
    },
    {
      id: 2,
      name: "Edmilson Kayce Novele",
      phone: "845656784",
    },
    {
      id: 3,
      name: "Carlos Macaneta",
      phone: "845656784",
    },
    {
      id: 4,
      name: "Neide Loumina Reginaldo Souto",
      phone: "845656784",
    },
    {
      id: 5,
      name: "Zefanias Rodrigues Tembe",
      phone: "845656784",
    },
  ];

  const [laboral, setLaboral] = useState();
  const [posLaboral, setPosLaboral] = useState();
  const [loadingL, setLoadingL] = useState(true);
  const [loadingP, setLoadingP] = useState(true);
  const [student, setStudent] = useState();

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        regime: "Laboral",
        course: course,
      }),
    };
    fetch(
      `https://tivanegamestudio.co.mz/api_maza.php/api/estudantes/filterByCourseAndRegime`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Laboral", data);
        setLaboral(data);
        setLoadingL(false);
      });
  }, [course]);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        regime: "Pós Laboral",
        course: course,
      }),
    };
    fetch(
      `https://tivanegamestudio.co.mz/api_maza.php/api/estudantes/filterByCourseAndRegime`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Pos Laboral", data);
        setPosLaboral(data);
        setLoadingP(false);
      });
  }, [course]);

  const [showStudent, setShowStudent] = useState(false);
  const openStudent = (student) => {
    setStudent(student);
    setShowStudent(true);
  };
  const closeStudent = () => {
    setShowStudent(false);
  };

  return (
    <div className="container-fluid">
      <div className="row bg-white">
        <div className="col-12 col-md-6 p-0 border-right">
          <div className="semester-title shadow">
            <h6 className="fw-bold">Laboral</h6>
          </div>
          <List className={`${classes.root}`}>
            {loadingL ? (
              <div className="py-5">
                <CircularProgress className="my-5" />
              </div>
            ) : (
              <>
                {" "}
                {laboral?.length ? (
                  <>
                    {laboral?.map((student, index) => (
                      <ListItem key={index} className={`py-3 `}>
                        <ListItemAvatar>
                          {student?.image_link === "Sem imagem" ? (
                            <Avatar className={classes.green}>
                              {student.name.charAt(0)}
                            </Avatar>
                          ) : (
                            <Avatar
                              src={student?.image_link}
                              alt={student?.name}
                              title={student?.name}
                              className="border"
                            />
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span className="text-dark">{student?.name} </span>
                          }
                          secondary={student?.phone}
                        />
                        <a href={`tel:+${student?.phone}`}>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            style={{ color: "#0EC244" }}
                          >
                            <Call fontSize="small" />
                          </IconButton>
                        </a>
                        <IconButton
                          edge="end"
                          aria-label="details"
                          className="ms-3"
                          style={{ color: "#2396EF" }}
                          onClick={() => openStudent(student)}
                        >
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </ListItem>
                    ))}
                  </>
                ) : (
                  <div className="py-5">
                    <span className="my-5">Nenhum estudante encontrado!</span>
                  </div>
                )}
              </>
            )}
          </List>
        </div>
        <div className="col-12 col-md-6 p-0">
          <div className="semester-title shadow">
            <h6 className="fw-bold">Pós Laboral</h6>
          </div>

          <List className={`${classes.root}`}>
            {loadingP ? (
              <div className="py-5">
                <CircularProgress className="my-5" />
              </div>
            ) : (
              <>
                {posLaboral?.length ? (
                  <>
                    {posLaboral?.map((student, index) => (
                      <ListItem key={index} className={`py-3 `}>
                        <ListItemAvatar>
                          <Avatar className={classes.green}>
                            {student.name.charAt(0)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span className="text-dark">{student?.name} </span>
                          }
                          secondary={student?.phone}
                        />
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          style={{ color: "#0EC244" }}
                        >
                          <Call fontSize="small" />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="details"
                          className="ms-3"
                          style={{ color: "#2396EF" }}
                          onClick={() => openStudent(student)}
                        >
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </ListItem>
                    ))}
                  </>
                ) : (
                  <div className="py-5 d-flex align-items-center justify-content-center">
                    <span className="my-5">Nenhum estudante encontrado!</span>
                  </div>
                )}
              </>
            )}
          </List>
        </div>
      </div>
      <SeeStudent
        show={showStudent}
        closeModal={closeStudent}
        student={student}
      />
    </div>
  );
}
