import React from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import DashboardHomePage from "../pages/dashboard/home";
import MaterialPage from "../pages/dashboard/material";
import StudentsPage from "../pages/dashboard/students";
import SubjectsPage from "../pages/dashboard/subjects";
import HelpPage from "../pages/help";
import HomePage from "../pages/home";
import NotFoundPage from "../pages/notFoundPage";
import RegistStudentPage from "../pages/regist";

const Routing = () => {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="*" element={<NotFoundPage />} />
        <Route exact path="/" element={<Navigate replace to={'home'} />} />
        <Route exact path="/home" element={<HomePage />} />
        <Route exact path="/help" element={<HelpPage />} />
        <Route exact path="/regist/student" element={<RegistStudentPage />} />
        <Route exact path="/dashboard" element={<DashboardHomePage />} />
        <Route exact path="/dashboar/:course/:semester" element={<MaterialPage />} />
        <Route exact path="/subjects" element={<SubjectsPage />} />
        <Route exact path="/students" element={<StudentsPage />} />
      </Routes>
    </HashRouter>
  );
};

export default Routing;
