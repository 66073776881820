import React from "react";
import Divider from "@material-ui/core/Divider";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import FunctionsIcon from "@material-ui/icons/Functions";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import CollectionsBookmarkOutlinedIcon from "@material-ui/icons/CollectionsBookmarkOutlined";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Icon } from "@iconify/react";
import { NavLink, useParams } from "react-router-dom";

export default function DrawerMenu({ classes }) {
  const { course } = useParams();
  const [openInfo, setOpenInfo] = React.useState(true);
  const [openMath, setOpenMath] = React.useState(false);
  const [openEst, setOpenEst] = React.useState(false);
  const [openCig, setOpenCig] = React.useState(false);

  const handleClickInfo = () => {
    setOpenInfo(!openInfo);
  };
  const handleClickMath = () => {
    setOpenMath(!openMath);
  };
  const handleClickEst = () => {
    setOpenEst(!openEst);
  };
  const handleClickCig = () => {
    setOpenCig(!openCig);
  };

  const navigateTo = (course, semester) => {
    return `/dashboar/${course}/${semester}`;
  };

  let activeStyle = {
    color: "#4EAB70",
    textDecoration: "none",
  };

  let unactiveStyle = {
    color: "inherit",
    textDecoration: "none",
  };

  return (
    <div>
      <div className={classes.toolbar} />
      <List className="mt-4">
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
          to={"/dashboard"}
        >
          <ListItem button>
            <ListItemIcon>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </NavLink>
        <Divider />
        <ListItem button onClick={handleClickInfo} className={course === "informatica" && "course-item"}>
          <ListItemIcon>
            <LaptopMacIcon />
          </ListItemIcon>
          <ListItemText primary="Informática" />
          {openInfo ? (
            <ExpandMore fontSize="small" className="text-secondary" />
          ) : (
            <ChevronRightOutlinedIcon
              fontSize="small"
              className="text-secondary"
            />
          )}
        </ListItem>
        <Collapse in={openInfo} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={navigateTo("informatica", "1")}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <LooksOneIcon className="semester-icon" />
                </ListItemIcon>
                <ListItemText primary="1º Semestre" />
              </ListItem>
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={navigateTo("informatica", "2")}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <LooksTwoIcon className="semester-icon" />
                </ListItemIcon>
                <ListItemText primary="2º Semestre" />
              </ListItem>
            </NavLink>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickMath} className={course === "matematica" && "course-item"}>
          <ListItemIcon>
            <FunctionsIcon />
          </ListItemIcon>
          <ListItemText primary="Matemática" />
          {openMath ? (
            <ExpandMore fontSize="small" className="text-secondary" />
          ) : (
            <ChevronRightOutlinedIcon
              fontSize="small"
              className="text-secondary"
            />
          )}
        </ListItem>
        <Collapse in={openMath} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={navigateTo("matematica", "1")}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <LooksOneIcon className="semester-icon" />
                </ListItemIcon>
                <ListItemText primary="1º Semestre" />
              </ListItem>
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={navigateTo("matematica", "2")}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <LooksTwoIcon className="semester-icon" />
                </ListItemIcon>
                <ListItemText primary="2º Semestre" />
              </ListItem>
            </NavLink>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickEst} className={course === "estatistica" && "course-item"}>
          <ListItemIcon>
            <MultilineChartIcon />
          </ListItemIcon>
          <ListItemText primary="Estatística" />
          {openEst ? (
            <ExpandMore fontSize="small" className="text-secondary" />
          ) : (
            <ChevronRightOutlinedIcon
              fontSize="small"
              className="text-secondary"
            />
          )}
        </ListItem>
        <Collapse in={openEst} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={navigateTo("estatistica", "1")}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <LooksOneIcon className="semester-icon" />
                </ListItemIcon>
                <ListItemText primary="1º Semestre" />
              </ListItem>
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={navigateTo("estatistica", "2")}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <LooksTwoIcon className="semester-icon" />
                </ListItemIcon>
                <ListItemText primary="2º Semestre" />
              </ListItem>
            </NavLink>
          </List>
        </Collapse>

        <ListItem button onClick={handleClickCig} className={course === "cig" && "course-item"}>
          <ListItemIcon>
            <ExploreOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="C. de Informação Geográfica" />
          {openCig ? (
            <ExpandMore fontSize="small" className="text-secondary" />
          ) : (
            <ChevronRightOutlinedIcon
              fontSize="small"
              className="text-secondary"
            />
          )}
        </ListItem>
        <Collapse in={openCig} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={navigateTo("cig", "1")}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <LooksOneIcon className="semester-icon" />
                </ListItemIcon>
                <ListItemText primary="1º Semestre" />
              </ListItem>
            </NavLink>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
              to={navigateTo("cig", "2")}
            >
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <LooksTwoIcon className="semester-icon" />
                </ListItemIcon>
                <ListItemText primary="2º Semestre" />
              </ListItem>
            </NavLink>
          </List>
        </Collapse>

        <Divider />
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
          to={"/subjects"}
        >
          <ListItem button>
            <ListItemIcon>
              <CollectionsBookmarkOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Disciplinas" />
          </ListItem>
        </NavLink>

        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : unactiveStyle)}
          to={"/students"}
        >
          <ListItem button>
            <ListItemIcon>
              <Icon
                icon="mdi:account-school-outline"
                width="27"
                height="27"
                inline={true}
              />
            </ListItemIcon>
            <ListItemText primary="Estudantes" />
          </ListItem>
        </NavLink>
      </List>
    </div>
  );
}
