import React, { useEffect, useState } from "react";
import {
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  TextareaAutosize,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import CloseIcon from "@material-ui/icons/Close";
import { Spinner } from "react-bootstrap";
import SnackBar from "./snackbar";
import { useParams } from "react-router-dom";
import { PhotoCamera } from "@material-ui/icons";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { storage } from "../../firebase";
import subjects from "../utils/subjectsList.json";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4EAB70", //your color
    },
  },
});

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const AdicionarMaterial = (props) => {
  const classes = useStyles();

  const { course, semester } = useParams();

  /* --------------------DADOS DO FORMULÁRIO---------------------- */

  /* 
      "name": "string",
    "type": "string",
    "level": 0,
    "course": "string",
    "semester": 0,
    "link": "string",
    "subject": "string"
  */
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState(" ");
  const [level, setLevel] = useState(0);
  const [file, setFile] = useState();
  const [link, setLink] = useState();
  const [subject, setSubject] = useState("");

  const [errorName, setErrorName] = useState(false);
  const [errorNameMessage, setErrorNameMessage] = useState(
    "Ex: Teste 1 de 2020"
  );

  const [errorType, setErrorType] = useState(false);
  const [errorTypeMessage, setErrorTypeMessage] = useState("");

  const [errorFile, setErrorFile] = useState(false);

  const [errorSubject, setErrorSubject] = useState(false);
  const [errorSubjectMessage, setErrorSubjectMessage] = useState("");

  const formSubmit = (event) => {
    event.preventDefault();

    if (errorName === false && errorType === false && errorFile === false) {
      console.log("SIM");
      handleUpload()
    } else {
      console.log("NÃO");
    }
  };

  const requiredFields = () => {
    if (name === "" || name === undefined) {
      setErrorName(true);
      setErrorNameMessage("Por favor, preencha este campo!");
    } else {
      setErrorName(false);
      setErrorNameMessage(true);
    }

    if (type === " " || type === undefined) {
      setErrorType(true);
      setErrorTypeMessage("Por favor, preencha este campo!");
    } else {
      setErrorType(false);
      setErrorTypeMessage(true);
    }

    if (
      image1 === "Nenhum ficheiro seleccionado." ||
      image1 === "Por favor, tem que seleccionar um ficheiro!"
    ) {
      setErrorFile(true);
      setImage1("Por favor, tem que seleccionar um ficheiro!");
    } else {
      setErrorFile(false);
    }
  };

  const dados = {
    name: name,
    type: type,
    level: 1,
    course: course,
    semester: semester,
    link: link,
    subject: subject,
  };

    const salvar = (link) => {
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        name: dados.name,
        type: dados.type,
        level: dados.level,
        course: dados.course,
        semester: dados.semester,
        link: link,
        subject: dados.subject,
      }),
    };
    fetch(
      "https://tivanegamestudio.co.mz/api_maza.php/api/files/save",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          setOpen(true);
          setLoader(false);
        }
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [image1, setImage1] = useState("Nenhum ficheiro seleccionado.");

  const handleChangeImage = (e) => {
    if (e.target.files[0]) {
      setErrorFile(false);
      setImage(e.target.files[0]);
      setImage1(e.target.files[0].name);
    }
  };

  const handleUpload = () => {
    setLoader(true);
    if (image) {
      console.log("Com imagem");
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              setLink(url);
              setImage1(image.name);
              salvar(url);
              console.log(url);
            });
        }
      );
    } else {
      console.log("Sem imagem");
      //salvar("Sem imagem");
    }
  };

  const cleanFields = () => {
    setName("");
    setType(" ");
    setLink("");
    setImage1("Nenhum ficheiro seleccionado.");
    setErrorName(false)
    setErrorType(false)
    setErrorFile(false)
    props.closeModal()
  };

  const [open, setOpen] = useState();

  const closeSnackbar = () => {
    setOpen(false);
  };

  const getSubject = (value) => {
    subjects.forEach((element) => {
      if (course === element.code && semester === element.semester)
        setSubject(element.subjects[value].name);
    });
  };

  useEffect(() => {
    getSubject(props.value);
  }, [course, semester, props.value]);

  return (
    <>
      <Dialog
        open={props.show}
        onClose={props.closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="fw-bold text-center">
          Adicionar material
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formSubmit}>
          <DialogContent>
            <div className="px-2">
              <div
                className="row pb-2 text-white"
                style={{ backgroundColor: "#4EAB70", borderRadius: 7 }}
              >
                <div className="col-12 col-md-9 mt-2 order-1 order-md-1">
                  <span className="">Curso:</span>{" "}
                  <h6
                    className="text-capitalize d-inline text-white"
                    style={{ fontSize: 15 }}
                  >
                    {" "}
                    {course}
                  </h6>
                </div>
                <div className="col-6 col-md-3 mt-2 order-3 order-md-2">
                  <span className="">Nível:</span>{" "}
                  <span
                    className="d-inline text-white"
                    style={{ fontSize: 15 }}
                  >
                    I
                  </span>
                </div>
                <div className="col-12 col-md-9 mt-2 order-2 order-md-3">
                  <span className="">Disciplina:</span>{" "}
                  <h6 className="d-inline text-white" style={{ fontSize: 14 }}>
                    {subject}
                  </h6>
                </div>
                <div className="col-6 col-md-3 mt-2 order-4 order-md-4">
                  <span className="">Semestre:</span>{" "}
                  <span
                    className="d-inline text-white"
                    style={{ fontSize: 17 }}
                  >
                    {semester}
                  </span>
                </div>
              </div>
            </div>
            <small className="text-danger">
              Por favor, preste atenção nos dados acima, referentes ao material
              que pretende submeter. É importante que o faça no lugar adequado
              para a fácil localização e utilização da plataforma.
            </small>

            <DialogContentText id="alert-dialog-description">
              <div className="row">
                <div className="col-md-12 mt-3">
                    <TextField
                      variant="standard"
                      fullWidth
                      placeholder="Nome:"
                      name="name"
                      type={"text"}
                      id="name"
                      className="shadow-sm rounded border-bottom"
                      value={name}
                      error={errorName}
                      onChange={(e) => setName(e.target.value)}
                      InputProps={{disableUnderline: true}}
                      inputProps={{style:{border: "none", paddingLeft: 10}}}
                    disabled={loader}
                    />
                  {errorName ? <FormHelperText className="text-danger">O Nome do ficheiro é obrigatório!</FormHelperText> : <FormHelperText>Ex: Teste 1 de 2020</FormHelperText>}
                </div>

                <div className="col-md-12 mt-4">
                    <TextField
                      select
                      variant="standard"
                      fullWidth
                      name="type"
                      placeholder="Seleccione a categoria:"
                      type={"text"}
                      id="type"
                      className="shadow-sm rounded border-bottom"
                      value={type}
                      defaultValue={type}
                      error={errorType}
                      onChange={(e) => setType(e.target.value)}
                      InputProps={{disableUnderline: true, style:{paddingLeft: 10, color: type===" " && 'rgba(0,0,0,0.5)'}}}
                      inputProps={{style:{border: "none"}}}
                    disabled={loader}
                    >
                      <MenuItem value={" "} disabled className="text-secondary">Seleccione a categoria:</MenuItem>
                      <MenuItem value={"Livro"}>Livro</MenuItem>
                      <MenuItem value={"Ficha"}>Ficha</MenuItem>
                      <MenuItem value={"Teste"}>Teste</MenuItem>
                      <MenuItem value={"Exame"}>Exame</MenuItem>
                    </TextField>
                  {errorName && <FormHelperText className="text-danger">A categoria do material é obrigatório!</FormHelperText>}
                </div>
                <div className="col-md-12 mt-4 mt-md-4">
                  <div className="text-center d-block d-md-flex justify-content-md-between align-items-center">
                    {" "}
                    <input
                      className="upload-photo"
                      id="contained-button-file"
                      multiple
                      onChange={handleChangeImage}
                      type="file"
                    disabled={loader}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        style={{
                          textTransform: "capitalize",
                          color: "#17A2B8",
                          backgroundColor: "#F5F5F5",
                        }}
                        className="text-center shadow-sm px-3 py-2"
                        component="span"
                        startIcon={
                          <Icon
                            icon="material-symbols:upload-rounded"
                            width="24"
                            height="24"
                            inline={true}
                          />
                        }
                    disabled={loader}
                      >
                        {" "}
                        {image1 === "Nenhum ficheiro seleccionado." ||
                        image1 === "Por favor, tem que seleccionar um ficheiro!"
                          ? "Carregar "
                          : "Alterar "}
                        {type || "Ficheiro"}
                      </Button>
                    </label>
                    <div className="">
                      <small
                        id="file-chosen"
                        className={`${errorFile && "text-danger"}`}
                      >
                        {image1}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="px-4">
            <Button
              className="text-capitalize mr-3"
              style={{ width: 100 }}
              onClick={() => cleanFields()}
              color="secondary"
              disabled={loader}
              >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="text-capitalize"
              style={{ color: "white", width: 100, backgroundColor: "#17A2B8" }}
              onClick={() => requiredFields()}
              disabled={loader}
              >
              {loader ? (
                      <Spinner
                        animation="border"
                        style={{ width: 24, height: 24 }}
                      />
                    ) : (
                      "Salvar"
                    )}
              
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <SnackBar
        open={open}
        handleClose={closeSnackbar}
        message={type + " adicionado(a) com sucesso!"}
        key={"bottom" + "center"}
        autoHideDuration={3000}
        snackPosition={{ vertical: "bottom", horizontal: "center" }}
        cor="success"
      />
    </>
  );
};
