export const getSubjects = (course, semester) => {
  switch (true) {
    case course === "informatica" && semester === "1":
      return [
        {
          name: "Estatística Básica",
          code: "EB",
        },
        {
          name: "Fundamentos de Geometria",
          code: "FG",
        },
        {
          name: "Introdução às TIC's",
          code: "ITICS",
        },
        {
          name: "Métodos de Estudo e Habilidades para a Vida",
          code: "MEHV",
        },
        {
          name: "Matemática Básica",
          code: "FG",
        },
      ];
    case course === "informatica" && semester === "2":
      return [
        {
          name: "Álgebra Linear e Geometria Analítica",
          code: "ALGA",
        },
        {
          name: "Análise Matemática I",
          code: "AM-I",
        },
        {
          name: "Estrutura e Gestão das Organizações ",
          code: "EGO",
        },
        {
          name: "Introdução à Informática",
          code: "II",
        },

        {
          name: "Lógica e Teoria de Conjuntos",
          code: "LTC",
        },
      ];
    case course === "matematica" && semester === "1":
      return [
        {
          name: "Estatística Básica",
          code: "EB",
        },
        {
          name: "Fundamentos de Geometria",
          code: "FG",
        },
        {
          name: "Introdução às TIC's",
          code: "ITICS",
        },
        {
          name: "Matemática Básica",
          code: "FG",
        },
        {
          name: "Métodos de Estudo e Habilidades para a Vida",
          code: "MEHV",
        },
      ];
    case course === "matematica" && semester === "2":
      return [
        {
          name: "Álgebra Linear",
          code: "ALGA",
        },
        {
          name: "Análise Matemática I",
          code: "AM-I",
        },
        {
          name: "Geometria Plana e espacial",
          code: "GPE",
        },
        {
          name: "Introdução à Informática",
          code: "II",
        },
        {
          name: "Estatística Básica",
          code: "EB",
        },
      ];
    case course === "estatistica" && semester === "1":
      return [
        {
          name: "Estatística Básica",
          code: "EB",
        },
        {
          name: "Introdução às TIC's",
          code: "II",
        },
        {
          name: "Logica e Teoria de Conjuntos",
          code: "LTC",
        },
        {
          name: "Matemática Básica",
          code: "MB",
        },
        {
          name: "Métodos de Estudo e Habilidades para a Vida",
          code: "MEHV",
        },
      ];
    case course === "estatistica" && semester === "2":
      return [
        {
          name: "Álgebra Linear e Geometria Analítica",
          code: "ALGA",
        },
        {
          name: "Análise Matemática I",
          code: "AM-I",
        },
        {
          name: "Economia",
          code: "Economia",
        },
        {
          name: "Estatística Descritiva",
          code: "ED",
        },
        {
          name: "Introdução à Informática",
          code: "II",
        },
      ];
    case course === "cig" && semester === "1":
      return [
        {
          name: "Estatística Básica",
          code: "EB",
        },
        {
          name: "Fundamentos de Geografia",
          code: "FG",
        },
        {
          name: "Matemática Básica",
          code: "FG",
        },
        {
          name: "Introdução às TIC's",
          code: "ITICS",
        },
        {
          name: "Métodos de Estudo e Habilidades para a Vida",
          code: "MEHV",
        },
      ];
    case course === "cig" && semester === "2":
      return [
        {
          name: "Álgebra Linear e Geometria Analítica",
          code: "ALGA",
        },
        {
          name: "Análise Matemática I",
          code: "AM-I",
        },
        {
          name: "Fundamentos de Geografia e Geomorfologia",
          code: "FGG",
        },
        {
          name: "Lógica e Teoria de Conjuntos",
          code: "LTC",
        },
        {
          name: "Probabilidade e Estatística",
          code: "PB",
        },
      ];
    default:
      return "";
  }
};
