import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import dateFormat from "dateformat";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { blue, blueGrey, green } from "@material-ui/core/colors";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  green: {
    backgroundColor: blueGrey[500],
  },
}));

export default function MaterialList() {
  const classes = useStyles();

  const material = [
    {
      id: "1",
      description: "Metodologias de Investigação e Pesquisa",
      created_at: dateFormat(new Date(), "dd-mm-yyyy HH:MM:ss"),
      type: "Livro",
    },
    {
      id: "2",
      description: "Manual de Sistemas de Informação",
      created_at: dateFormat(new Date(), "dd-mm-yyyy HH:MM:ss"),
      type: "Ficha",
    },
    {
      id: "3",
      description: "Exame de Recorrência",
      created_at: dateFormat(new Date(), "dd-mm-yyyy HH:MM:ss"),
      type: "teste",
    },
    {
      id: "4",
      description: "Teste 1",
      created_at: dateFormat(new Date(), "dd-mm-yyyy HH:MM:ss"),
      type: "teste",
    },
    {
      id: "5",
      description: "Metodologias de Investigação e Pesquisa",
      created_at: dateFormat(new Date(), "dd-mm-yyyy HH:MM:ss"),
      type: "Livro",
    },
    {
      id: "6",
      description: "Manual de Sistemas de Informação",
      created_at: dateFormat(new Date(), "dd-mm-yyyy HH:MM:ss"),
      type: "Ficha",
    },
    {
      id: "7",
      description: "Exame de Recorrência",
      created_at: dateFormat(new Date(), "dd-mm-yyyy HH:MM:ss"),
      type: "teste",
    },
  ];

  return (
    <div>
      <List className={`${classes.root} row g-5`}>
        {material?.map((mat, index) => (
          <ListItem
            key={index}
            className={`col-12 col-md-6 ${index % 2 === 0 && "border-right py-3"}`}
          >
            <ListItemAvatar>
              <Avatar className={classes.green}>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<span className="text-dark">{mat?.description} </span>}
              secondary={mat?.created_at}
            />
            <IconButton
              edge="end"
              aria-label="edit"
              style={{ color: "#006FB2" }}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              style={{ color: "#E43D30" }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <div className="d-flex justify-content-between align-items-center py-2 px-3 bg-white">
        <small className="">Total: 23</small>
        <div className="d-flex align-items-center">
          <small className="me-4">Página: 1 de 4</small>
          <Pagination
            count={4}
            size="large"
            page={1}
            //onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
