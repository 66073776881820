import React from "react";
import Appbar from "./appbar";
import logo from "../assets/img/logo.png";
import learning from "../assets/img/learning_img.png";
import course1 from "../assets/img/special_cource_1.png";
import course2 from "../assets/img/special_cource_2.png";
import course3 from "../assets/img/special_cource_3.png";
import advance_feature_img from "../assets/img/advance_feature_img.png";
import $ from "jquery";
import Footer from "./footer";
import { BarChart, Computer, Public } from "@material-ui/icons";
import { NavLink } from "react-router-dom";

export default function Home() {
  $(document).ready(function () {
    $(".counter").each(function () {
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 4000,
            easing: "swing",
            step: function (now) {
              $(this).text(Math.ceil(now));
            },
          }
        );
    });
  });
  return (
    <div className="">
      <Appbar />

      <section class="banner_part">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-xl-6">
              <div class="banner_text">
                <div class="banner_text_iner">
                  <h5 className="text-capitalize">
                    Departamento de Matemática e Informática
                  </h5>
                  <h1>Hoje caloiro, Amanhã Maza!</h1>
                  <p>
                    Parabéns pela admissão e seja bem-vindo ao DMI. A plataforma
                    Sou Maza vai lhe ajudar a se integrar nestes primeiros dias
                    e vai lhe manter informado sobre os principais eventos
                    durante o semestre.
                  </p>
                  <NavLink to={"/regist/student"} className="btn_1">
                    Me registar
                  </NavLink>
                  <NavLink to={"/help"} className="btn_2">
                    Pedir ajuda
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="feature_part mb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="section_tittle text-center ">
              <p>Primeiro Ano</p>
              <h2>Grupos do Whatsapp</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-xl-3">
              <div class="single_feature">
                <div class="single_feature_part shadow">
                  <span class="single_feature_icon">
                    <i class="bi bi-calculator"></i>
                  </span>
                  <h4>Matemática</h4>
                  <p>Selecione o seu regime</p>
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/Bo3jWmg6vCn70b3q0REz2V"
                    class="btn_1 mr-2"
                  >
                    Laboral <i class="bi bi-whatsapp"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/CnMsS5YmEre0mwz1zDrS70"
                    class="btn_1 mt-3"
                  >
                    Pos-Laboral <i class="bi bi-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3">
              <div class="single_feature">
                <div class="single_feature_part shadow">
                  <span class="single_feature_icon">
                    <i class="bi bi-pc-display-horizontal">
                      <Computer />
                    </i>
                  </span>
                  <h4>Informática</h4>
                  <p>Selecione o seu regime</p>
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/BCH9xspP0z08KkjrtxljU3"
                    class="btn_1 mr-2"
                  >
                    Laboral <i class="bi bi-whatsapp"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/Gfvgqp0IQNB9vx0POEmoGK"
                    class="btn_1 mt-3"
                  >
                    Pos-Laboral <i class="bi bi-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3">
              <div class="single_feature">
                <div class="single_feature_part shadow">
                  <span class="single_feature_icon">
                    <i class="ti-bar-chart">
                      <BarChart />
                    </i>
                  </span>
                  <h4>Estatística</h4>
                  <p>Selecione o seu regime</p>
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/DWE12zLOyT3Fz81JxZM3tA"
                    class="btn_1 mr-2"
                  >
                    Laboral <i class="bi bi-whatsapp"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/FxlIxSFQUtpAIkuea3SAB2"
                    class="btn_1 mt-3"
                  >
                    Pos-Laboral <i class="bi bi-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-3">
              <div class="single_feature">
                <div class="single_feature_part shadow single_feature_part_2">
                  <span class="single_service_icon style_icon">
                    <i class="bi bi-globe-europe-africa">
                      <Public />
                    </i>
                  </span>
                  <h4>CIG</h4>
                  <p>Selecione o seu regime</p>
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/GdcwMtL083WB1fgrhAf0UR"
                    class="btn_1 mr-2"
                  >
                    Laboral <i class="bi bi-whatsapp"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://chat.whatsapp.com/GGhNKUbeOxIBcIOnzgVeyp"
                    class="btn_1 mt-3"
                  >
                    Pos-Laboral <i class="bi bi-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*       <section class="learning_part">
        <div class="container">
          <div class="row align-items-sm-center align-items-lg-stretch">
            <div class="col-md-7 col-lg-7">
              <div class="learning_img">
                <img src={learning} alt="" />
              </div>
            </div>
            <div class="col-md-5 col-lg-5">
              <div class="learning_member_text">
                <h5>About us</h5>
                <h2>Learning with Love and Laughter</h2>
                <p>
                  Fifth saying upon divide divide rule for deep their female all
                  hath brind Days and beast greater grass signs abundantly have
                  greater also days years under brought moveth.
                </p>
                <ul>
                  <li>
                    <span class="ti-pencil-alt"></span>Him lights given i heaven
                    second yielding seas gathered wear
                  </li>
                  <li>
                    <span class="ti-ruler-pencil"></span>Fly female them whales
                    fly them day deep given night.
                  </li>
                </ul>
                <a href="#" class="btn_1">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="member_counter">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <div class="single_member_counter">
                <span class="counter">1024</span>
                <h4>All Teachers</h4>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single_member_counter">
                <span class="counter">960</span>
                <h4> All Students</h4>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single_member_counter">
                <span class="counter">1020</span>
                <h4>Online Students</h4>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single_member_counter">
                <span class="counter">820</span>
                <h4>Ofline Students</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="special_cource padding_top">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-5">
              <div class="section_tittle text-center">
                <p>popular courses</p>
                <h2>Special Courses</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-lg-4">
              <div class="single_special_cource">
                <img src={course1} class="special_img" alt="" />
                <div class="special_cource_text">
                  <a href="course-details.html" class="btn_4">
                    Web Development
                  </a>
                  <h4>$130.00</h4>
                  <a href="course-details.html">
                    <h3>Web Development</h3>
                  </a>
                  <p>
                    Which whose darkness saying were life unto fish wherein all
                    fish of together called
                  </p>
                  <div class="author_info">
                    <div class="author_img">
                      <img src="img/author/author_1.png" alt="" />
                      <div class="author_info_text">
                        <p>Conduct by:</p>
                        <h5>
                          <a href="#">James Well</a>
                        </h5>
                      </div>
                    </div>
                    <div class="author_rating">
                      <div class="rating">
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/star.svg" alt="" />
                        </a>
                      </div>
                      <p>3.8 Ratings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="single_special_cource">
                <img src={course2} class="special_img" alt="" />
                <div class="special_cource_text">
                  <a href="course-details.html" class="btn_4">
                    design
                  </a>
                  <h4>$160.00</h4>
                  <a href="course-details.html">
                    {" "}
                    <h3>Web UX/UI Design </h3>
                  </a>
                  <p>
                    Which whose darkness saying were life unto fish wherein all
                    fish of together called
                  </p>
                  <div class="author_info">
                    <div class="author_img">
                      <img src="img/author/author_2.png" alt="" />
                      <div class="author_info_text">
                        <p>Conduct by:</p>
                        <h5>
                          <a href="#">James Well</a>
                        </h5>
                      </div>
                    </div>
                    <div class="author_rating">
                      <div class="rating">
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/star.svg" alt="" />
                        </a>
                      </div>
                      <p>3.8 Ratings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="single_special_cource">
                <img src={course3} class="special_img" alt="" />
                <div class="special_cource_text">
                  <a href="course-details.html" class="btn_4">
                    Wordpress
                  </a>
                  <h4>$140.00</h4>
                  <a href="course-details.html">
                    {" "}
                    <h3>Wordpress Development</h3>{" "}
                  </a>
                  <p>
                    Which whose darkness saying were life unto fish wherein all
                    fish of together called
                  </p>
                  <div class="author_info">
                    <div class="author_img">
                      <img src="img/author/author_3.png" alt="" />
                      <div class="author_info_text">
                        <p>Conduct by:</p>
                        <h5>
                          <a href="#">James Well</a>
                        </h5>
                      </div>
                    </div>
                    <div class="author_rating">
                      <div class="rating">
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/color_star.svg" alt="" />
                        </a>
                        <a href="#">
                          <img src="img/icon/star.svg" alt="" />
                        </a>
                      </div>
                      <p>3.8 Ratings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="advance_feature learning_part">
        <div class="container">
          <div class="row align-items-sm-center align-items-xl-stretch">
            <div class="col-md-6 col-lg-6">
              <div class="learning_member_text">
                <h5>Advance feature</h5>
                <h2>Our Advance Educator Learning System</h2>
                <p>
                  Fifth saying upon divide divide rule for deep their female all
                  hath brind mid Days and beast greater grass signs abundantly
                  have greater also use over face earth days years under brought
                  moveth she star
                </p>
                <div class="row">
                  <div class="col-sm-6 col-md-12 col-lg-6">
                    <div class="learning_member_text_iner">
                      <span class="bi bi-pencil-square"></span>
                      <h4>Learn Anywhere</h4>
                      <p>
                        There earth face earth behold she star so made void two
                        given and also our
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-12 col-lg-6">
                    <div class="learning_member_text_iner">
                      <span class="bi bi-file-earmark-arrow-up"></span>
                      <h4>Expert Teacher</h4>
                      <p>
                        There earth face earth behold she star so made void two
                        given and also our
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="learning_img">
                <img src={advance_feature_img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
}
