import React from "react";
import RegistStudent from "../../components/regist";



const RegistStudentPage = () => {
  return (
    <div >
      <RegistStudent />
    </div>
  );
};
export default RegistStudentPage;

