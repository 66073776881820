import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  absolute: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(2),
  },
}));

export default function FloatButton({title, action, disabled, color, icon, backgroundColor}) {
  const classes = useStyles();

  return (
    <div>
      <Tooltip title={title} aria-label="edit" onClick={action} disabled={disabled || false}>
        <Fab className={classes.absolute} style={{backgroundColor: backgroundColor, color: color}}>
          {icon}
        </Fab>
      </Tooltip>
    </div>
  );
}
