import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useParams, useSearchParams } from "react-router-dom";
import { getSubjects } from "../utils/subjects";
import MaterialList from "./tabContent.js/materialList";
import { Button, ButtonGroup, Chip } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import FloatButton from "./floatButton";
import { Add, Edit } from "@material-ui/icons";
import { AdicionarMaterial } from "./addMaterial";
import subjects from "../utils/subjectsList.json";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "orange",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "green",
      borderTop: "none",
    },
  },
  chips: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },

  buttons: {
    "&.MuiButton-outlined": {
      borderColor: "rgba(24, 119, 212, 0.1)",
      textTransform: "capitalize",
      paddingLeft: 27,
      paddingRight: 27,
    },
  },
}));

export default function Material() {
  const { course, semester } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [subject, setSubject] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const closeAddMaterial = () => {
    setShowAddMaterial(false);
  };


  return (
    <div className="">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          {getSubjects(course, semester)?.map((subject, index) => (
            <Tab key={index} label={subject.name} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>

      <div className="pb-4 d-flex justify-content-end buttons-group bg-white">
        <div className={` shadow-sm w-100`}>
        <h5 className="mt-4">Lista de Material de {getSubjects(course, semester)[value].name} </h5>
          <div className={`${classes.chips} pt-3 pb-2` }>
                      <small>Filtros:</small>
          <Chip
            className="px-4 chip-filter"
            icon={<MenuBookIcon fontSize="small" className="chip-iconn" />}
            label="Livros"
          />
          <Chip
            className="px-4 chip-filter"
            icon={<PictureAsPdfIcon fontSize="small" className="chip-iconn" />}
            label="Fichas"
          />
          <Chip
            className="px-4 chip-filter"
            icon={<ImageIcon fontSize="small" className="chip-iconn" />}
            label="Testes"
          />
          <Chip
            className="px-4 chip-filter"
            icon={<ListAltIcon fontSize="small" className="chip-iconn" />}
            label="Exames"
          />
                    <Chip
            className="px-4 chip-filter1"
            icon={<ClearAllIcon fontSize="small" className="chip-iconn1" />}
            label="Todos"
          />
          </div>

        </div>

      </div>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <MaterialList />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <MaterialList />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <MaterialList />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <MaterialList />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <MaterialList />
        </TabPanel>
      </SwipeableViews>

      <FloatButton
        title="Adicionar material"
        color="#fff"
        backgroundColor="#4EAB70"
        icon={<Add />}
        action={() => setShowAddMaterial(true)}
      />

      <AdicionarMaterial
        show={showAddMaterial}
        closeModal={closeAddMaterial}
        value={value}
      />
    </div>
  );
}
