import React from "react";

export default function Dashboard() {
  return (
    <div className="">
      <h1>HELLO MY BEST FRIEND!!!!!</h1>
      <h1>THIS IS THE DASHBOARD</h1>
    </div>
  );
}
