import React from "react";
import AppbarDrawer from "../../../components/dashboard/drawer";
import StudentsManagement from "../../../components/dashboard/studentsManangement/gestaoStudents";

const StudentsPage = () => {
  return (
    <div>
      <AppbarDrawer>
        <StudentsManagement />
      </AppbarDrawer>
    </div>
  );
};
export default StudentsPage;
