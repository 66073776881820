import React from "react";
import Appbar from "./appbar";
import logo from "../assets/img/logo.png";
import nedmi from "../assets/img/nedmi.jpeg";
import learning from "../assets/img/learning_img.png";
import course1 from "../assets/img/special_cource_1.png";
import course2 from "../assets/img/special_cource_2.png";
import course3 from "../assets/img/special_cource_3.png";
import advance_feature_img from "../assets/img/advance_feature_img.png";
import $ from "jquery";

export default function Footer() {
  return (
    <footer class="footer-area">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-sm-6 col-md-4 col-xl-3">
            <div class="single-footer-widget footer_1">
              <a href="index.html">
                {" "}
                <img src={logo} alt="" />{" "}
              </a>
              <p>
                Esta plataforma é desenvolvoda no âmbito de apoio aos recém
                admitidos em termos de material e integração no ambiemte do
                ensino superior.{" "}
              </p>
              <p>Encontre todo material disponível aqui!! </p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-xl-4">
            <div class="single-footer-widget footer_2">
              <h4>NEDMI</h4>
              <p>
                Núcleo de Estudantes do Departamento de Matemática e Informática
              </p>
              <form action="#">
              <img src={nedmi} alt="" style={{width: 100}} />{" "}

              </form>
              <div class="social_icon">
                <a href="#">
                  {" "}
                  <i class="bi bi-globe-asia-australia"></i>{" "}
                </a>
                <a href="#">
                  {" "}
                  <i class="bi bi-facebook"></i>{" "}
                </a>
                <a href="#">
                  {" "}
                  <i class="bi bi-instagram"></i>{" "}
                </a>
                <a href="#">
                  {" "}
                  <i class="bi bi-whatsapp"></i>{" "}
                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-md-4">
            <div class="single-footer-widget footer_2">
              <h4>Contacte nos</h4>
              <div class="contact_info">
                <p>
                  <span> Endereço: </span>Faculdade de Ciências, 3453 Avenida
                  Julius Nyerere, Maputo{" "}
                </p>
                <p>
                  <span> Phone: </span> (+258) 875 085 818
                </p>
                <p>
                  <span> Email: </span>soumaza.nedmi@gmail.com{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="copyright_part_text text-center">
              <div class="row">
                <div class="col-lg-12">
                  <p class="footer-text m-0">
                    {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                    Copyright &copy; 2023 - Todos direitos reservados | NEDMI{" "}
                    {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
